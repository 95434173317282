import React, { useState } from 'react';

const DynamicLinkForm = () => {
    const [prefix, setPrefix] = useState('');
    const [urlSuffix, setUrlSuffix] = useState('');
    const [fallbackUrl, setFallbackUrl] = useState('');
    const [androidBehavior, setAndroidBehavior] = useState({
        deepLink: true,
        fallbackOption: 'custom', // 'custom' or 'playStore'
        fallbackUrl: '',
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = { prefix, urlSuffix, fallbackUrl, androidBehavior };

        const response = await fetch('https://api99.futureapps.in/create-link', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        });

        const result = await response.json();
        console.log(`Dynamic link created: ${result}`);

        if (result.success) {
            console.log(`Dynamic link created: ${result.shortLink}`);
        } else {
            console.log('Failed to create link', result);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <label>
                Domain Prefix:
                <input value={prefix} onChange={(e) => setPrefix(e.target.value)} />
            </label>
            <label>
                URL Suffix:
                <input value={urlSuffix} onChange={(e) => setUrlSuffix(e.target.value)} />
            </label>
            <label>
                Fallback URL:
                <input value={fallbackUrl} onChange={(e) => setFallbackUrl(e.target.value)} />
            </label>
            <label>
                Android Behavior:
                <select
                    value={androidBehavior.fallbackOption}
                    onChange={(e) =>
                        setAndroidBehavior({ ...androidBehavior, fallbackOption: e.target.value })
                    }
                >
                    <option value="custom">Custom URL</option>
                    <option value="playStore">Google Play Page</option>
                </select>
                {androidBehavior.fallbackOption === 'custom' && (
                    <input
                        value={androidBehavior.fallbackUrl}
                        onChange={(e) =>
                            setAndroidBehavior({ ...androidBehavior, fallbackUrl: e.target.value })
                        }
                        placeholder="Enter custom fallback URL"
                    />
                )}
            </label>
            <button type="submit">Create Link</button>
        </form>
    );
};

export default DynamicLinkForm;
