import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { darkTheme } from '../../utils/Themes'
import Navbar from '../Navbar'
import StyledStarsCanvas from '../canvas/Stars';
import { AnimatePresence } from 'framer-motion';
import Footer from '../sections/Footer';
import CodeSnippet from '../common/CodeSnippet';


const Body = styled.div`
  background-color: ${({ theme }) => theme.bg};
  width: 100%;
  overflow-y: auto; 
  position: relative;
  `;

const Card = styled.div`
  width: 90%;
  height: auto;
  background-color: ${({ theme }) => theme.card};
  border-radius: 10px;
  box-shadow: 0 0 12px 4px rgba(0, 0, 0, 0.4);
  // overflow: hidden;
  padding: 26px 20px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  transition: all 0.5s ease-in-out;
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 0 50px 4px rgba(0, 0, 0, 0.6);
    filter: brightness(1.1);
  }
`;
const Image = styled.img`
  width: 100%;
  height: 500px;
  background-color: ${({ theme }) => theme.white};
  border-radius: 10px;
  box-shadow: 0 0 16px 2px rgba(0, 0, 0, 0.3);
`;
const Tags = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 4px;
`;
const Tag = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.primary};
  background-color: ${({ theme }) => theme.primary + 15};
  padding: 2px 8px;
  border-radius: 10px;
`;
const Details = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0px;
  padding: 0px 2px;
`;
const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: ${({ theme }) => theme.text_secondary};
  overflow: hidden;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const Date = styled.div`
  font-size: 12px;
  margin-left: 2px;
  font-weight: 400;
  color: ${({ theme }) => theme.text_secondary + 80};
  @media only screen and (max-width: 768px) {
    font-size: 10px;
  }
`;
const Description = styled.div`
  font-weight: 400;
  color: ${({ theme }) => theme.text_secondary + 99};
  overflow: hidden;
  margin-top: 8px;
  // display: -webkit-box;
  max-width: 100%;
  // -webkit-line-clamp: 3;
  // -webkit-box-orient: vertical;
  // text-overflow: ellipsis;
`;
// const Members = styled.div`
//   display: flex;
//   align-items: center;
//   padding-left: 10px;
// `;
// const Avatar = styled.img`
//   width: 38px;
//   height: 38px;
//   border-radius: 50%;
//   margin-left: -10px;
//   background-color: ${({ theme }) => theme.white};
//   box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
//   border: 3px solid ${({ theme }) => theme.card};
// `;



const tags = [
  "React Js",
  "PostgressSQL",
  "Node Js",
  "Express Js",
  "Redux",
  "React Flow",
]

// const member= [
//   {
//     name: "Future Apps",
//     img: "https://avatars.githubusercontent.com/u/64485885?v=4",
//     linkedin: "https://www.linkedin.com/in/rishav-chanda-b89a791b3/",
//     github: "https://github.com/rishavchanda/",
//   }
// ]

function BlogDetails() {
  return (
    <ThemeProvider theme={darkTheme}>
      <Navbar />
      <Body className=''>
        {/* <StyledStarsCanvas /> */}
        <AnimatePresence>
          <div className=' d-align-justify-center flex-column mt-50px pt-70px '>
            <Card className='positiob-relative'>
              <Image src={'https://static.vecteezy.com/system/resources/previews/025/793/343/non_2x/capturing-the-essence-of-a-multicultural-qa-engineer-meticulously-engaged-in-uncovering-and-resolving-bugs-in-software-code-preparing-for-a-flawless-launch-with-determination-ai-generative-free-photo.jpg'} />
              <Tags>
                {tags?.map((tag, index) => (
                  <Tag>{tag}</Tag>
                ))}
              </Tags>
              <Details>
                <Title>Testing</Title>
                <Date>187277</Date>
                <Description>Lorem Ipsum is a placeholder text commonly used in publishing and graphic design12345. It is used to demonstrate the visual form of a document or typeface without relying on meaningful content. Lorem Ipsum is scrambled Latin text that emphasizes design over content34.</Description>
              </Details>

              <CodeSnippet/>
              {/* <Members>
                {member?.map((member) => (
                  <Avatar src={member.img} />
                ))}
              </Members> */}
            </Card>

            <Footer />
          </div>
        </AnimatePresence>
      </Body>
    </ThemeProvider>
  )
}

export default BlogDetails