import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { RouterProvider } from "react-router-dom";
import { RouterParent } from "./App";

// Css
import 'bootstrap/dist/css/bootstrap.min.css';
import 'future-tint/dist/common.min.css';
import './CSS/Index.css'


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={RouterParent}/>
  </React.StrictMode>
);