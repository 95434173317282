import React from 'react';
import { useCopyClipboard } from 'react-fu-hooks';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/cjs/styles/hljs';

const CodeSnippet = () => {
  const { copied, copyToClipboard } = useCopyClipboard();


  const codeString = `const express = require('express');
const cors = require('cors');
const crypto = require('crypto');
const { Cashfree } = require('cashfree-pg');require('dotenv').config();const app = express();

app.use(cors());
app.use(express.json());
app.use(express.urlencoded({ extended: true }));


Cashfree.XClientId = process.env.CLIENT_ID;
Cashfree.XClientSecret = process.env.CLIENT_SECRET;
Cashfree.XEnvironment = Cashfree.Environment.SANDBOX;

function generateOrderId() {
    const uniqueId = crypto.randomBytes(16).toString('hex');
    const hash = crypto.createHash('sha256');
    hash.update(uniqueId);
    const orderId = hash.digest('hex');return orderId.substr(0, 12);
}


app.get('/', (req, res) => {
    res.send('Hello World!');
})


app.get('/payment', async (req, res) => {
  try {let request = {
    "order_amount": 1.00,
    "order_currency": "INR",
    "order_id": await generateOrderId(),
    "customer_details": {
    "customer_id": "webcodder01",
    "customer_phone": "9999999999",
    "customer_name": "Web Codder",
    "customer_email": "webcodder@example.com"
  },
}

Cashfree.PGCreateOrder("2023-08-01", request)
    .then(response => {
        console.log(response.data);
        res.json(response.data);}).catch(error => {
        console.error(error.response.data.message);
    })
    } catch (error) {
        console.log(error);
}})
        

app.post('/verify', async (req, res) => {
    try {
      let { orderId } = req.body;Cashfree.PGOrderFetchPayments("2023-08-01", orderId)
          .then((response) => {
              res.json(response.data);
          })
          .catch(error => {
              console.error(error.response.data.message);
          })
    } catch (error) {
      console.log(error);
    }
})
    

app.listen(8000, () => {
    console.log('Server is running on port 8000');
})`;

  return (
    <div className="code-block">
      <div className='d-flex'>
        <div className="code-header flex-grow-1">
          <span className="circle red"></span>
          <span className="circle yellow"></span>
          <span className="circle green"></span>

        </div>
        {copied && <p className="fs-14px text-white flex-grow-1 m-0">Copied to clipboard!</p>}
        <i className="far fa-copy pointer" onClick={() => copyToClipboard(codeString)}></i>
      </div>

      {/* <div className=""> */}
        <SyntaxHighlighter language="javascript" style={a11yDark} className={'code-content mt-15px'}>
          {codeString}
        </SyntaxHighlighter>
      {/* </div> */}

    </div>
  );
};

export default CodeSnippet;
